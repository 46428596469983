/* SessionWarningModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000; /* ensure overlay is on top */
  }
  
  .session-modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 2100; /* above the overlay */
  }
  
  .session-modal p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .session-modal button {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    color: #fff;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .session-modal button:hover {
    background-color: #0056b3;
  }
  