/* KeyGenPopup.css */
.key-gen-container {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .status-indicator {
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 4px;
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    min-height: 2rem;
  }
  
  .wallet-info {
    padding: 1rem;
    background: #e9ecef;
    border-radius: 4px;
    margin: 1rem 0;
  }
  
  .security-notice {
    margin-top: 2rem;
    color: #6c757d;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .security-notice .bi {
    font-size: 1.5rem;
  }