@tailwind base;
@tailwind components;
@tailwind utilities;

/* ----------------------
   CSS Variables for Theming
   ---------------------- */

/* Default Light Mode Variables */
:root {
  --bg-gradient: linear-gradient(to right, #e2e8f0, #edf2f7);
  --bg-container: #fdfcfc;
  --text-color: #1a202c;
  --header-bg: rgba(52, 39, 245, 0.6);
  --header-text: #1a202c;
  --sidebar-bg: rgba(127, 167, 219, 0.60);
  --sidebar-text: #1a202c;
  --main-bg: #f2f2f2;
  --button-bg: linear-gradient(135deg, #007bff, #6a11cb);
  --button-hover-bg: linear-gradient(135deg, #0056b3, #4a0d8e);
  --bg1-gradient: 
    radial-gradient(circle, rgb(170, 162, 162), #333 30%, rgba(88,84,84,0.226) 0%),
    radial-gradient(circle at 40px 40px, rgb(233,228,228), #333 30%, rgba(88,84,84,0) 0%),
    radial-gradient(circle at 600px 200px, rgb(233,228,228), #333 10%, rgba(88,84,84,0) 0%),
    radial-gradient(circle at 800px 100px, rgb(233,228,228), #333 10%, rgba(88,84,84,0) 0%),
    radial-gradient(circle at 700px 500px, rgb(233,228,228), #333 2%, rgba(88,84,84,0.123) 0%),
    radial-gradient(circle at 200px 400px, rgb(233,228,228), #333 1%, rgba(88,84,84,0) 0%),
    radial-gradient(circle at 300px 700px, rgb(233,228,228), #333 15%, rgba(88,84,84,0) 0%),
    radial-gradient(circle at 900px 500px, rgb(233,228,228), #333 20%, rgba(88,84,84,0) 0%),
    radial-gradient(circle at 650px 400px, rgb(233,228,228), #333 10%, rgba(88,84,84,0) 0%),
    radial-gradient(circle at 600px 600px, rgb(233,228,228), #333 1%, rgba(88,84,84,0.959) 0%);
}

/* Dark Mode Variables */
.dark:root {
  --bg-gradient: linear-gradient(to right, #1a1a2e, #16213e);
  --bg-container: #121212;
  --text-color: #ffffff;
  --header-bg: rgba(30, 30, 30, 0.8);
  --header-text: #ffffff;
  --sidebar-bg: rgba(20, 20, 20, 0.85);
  --sidebar-text: #ffffff;
  --main-bg: rgba(25, 25, 25, 0.9);
  --button-bg: linear-gradient(135deg, #007bff, #6a11cb);
  --button-hover-bg: linear-gradient(135deg, #0056b3, #4a0d8e);
  --bg1-gradient:
  radial-gradient(circle, rgb(129, 125, 125), #333 30%, rgba(0, 0, 0, 0) 0%),
  radial-gradient(circle at 40px 40px, rgb(165, 164, 164), #333 30%, rgba(88,84,84,0) 0%),
  radial-gradient(circle at 600px 200px, rgb(158, 153, 153), #333 10%, rgba(88,84,84,0) 0%),
  radial-gradient(circle at 800px 100px, rgb(133, 130, 130), #333 10%, rgba(88,84,84,0) 0%),
  radial-gradient(circle at 700px 500px, rgb(121, 120, 120), #333 2%, rgba(88,84,84,0.123) 0%),
  radial-gradient(circle at 200px 400px, rgb(139, 138, 138), #333 1%, rgba(88,84,84,0) 0%),
  radial-gradient(circle at 300px 700px, rgb(117, 114, 114), #333 15%, rgba(88,84,84,0) 0%),
  radial-gradient(circle at 900px 500px, rgb(107, 102, 102), #333 20%, rgba(88,84,84,0) 0%),
  radial-gradient(circle at 650px 400px, rgb(124, 121, 121), #333 10%, rgba(88,84,84,0) 0%),
  radial-gradient(circle at 600px 600px, rgb(128, 123, 123), #333 1%, rgb(54, 51, 51) 0%);
}


/* ----------------------
   Global Styles
   ---------------------- */

/* General Styling */
body {
  @apply font-sans;
  font-family: 'Inter', sans-serif;
  background: var(--bg-gradient);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: var(--text-color);
}

/* Container Layout */
.container {
  width: 90%;
  max-width: 1200px;
  padding: 20px;
  background: var(--bg-container);
  color: var(--text-color);
}

/* Header Styling */
.header {
  background: var(--header-bg);
  padding: 15px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--header-text);
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Sidebar */
.sidebar {
  background: var(--sidebar-bg);
  color: var(--sidebar-text);
  width: 60px;
  height: 100vh;
  padding: 10px;
  transition: width 0.3s ease-in-out;
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.2);
}
.sidebar:hover {
  width: 200px;
}
.sidebar .menu-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.sidebar .menu-item:hover {
  background: rgba(50, 50, 50, 0.7);
}
.sidebar .menu-item span {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.sidebar:hover .menu-item span {
  opacity: 1;
  margin-left: 10px;
}

/* Main Content */
.main-content {
  margin-left: 60px;
  padding: 20px;
  flex: 1;
  min-height: 100vh;
  background: var(--main-bg);
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

/* ----------------------
   Buttons - Main Pages & Login/Signup
   ---------------------- */
   button {
    padding: 5px 30px;
    border-radius: 50px;
    cursor: pointer;
    border: 2px solid hsl(261deg 80% 48%);
    background-color: white;
    color: #1a202c; /* Ensures text color remains constant */
    font-weight: bold;
    box-shadow: rgba(182, 181, 181, 0.05) 0 0 8px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-size: 15px;
    transition: all 0.5s ease;
  }
  
  /* Keep text color fixed on hover */
  button:hover {
    letter-spacing: 3px;
    background-color: hsl(261deg 80% 48%);
    color: #ffffff; /* Ensure text stays white on hover */
    box-shadow: rgb(93 24 220) 0px 2px 10px 0px;
  }
  
  /* Keep text color fixed on click */
  button:active {
    letter-spacing: 3px;
    background-color: hsl(261deg 80% 48%);
    color: #ffffff; /* Prevents color change */
    box-shadow: rgb(93 24 220) 0px 0px 0px 0px;
    transform: translateY(10px);
    transition: 100ms;
  }
  
  /* Prevent Dark Mode from affecting button text */
  .dark button {
    color: #dfe2e7 !important; /* Forces button text to stay dark */
  }

  
  /* When disabled, reduce opacity and show 'not-allowed' cursor */
  .button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

/* ----------------------
   Forms & Login Styling
   ---------------------- */
.form-container {
  background: rgba(30, 30, 30, 0.9);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  text-align: center;
}
.form-container h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: var(--header-text);
}
.form-container input,
.login-card input,
.otp-input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid rgba(150, 150, 150, 0.3);
  background: var(--bg-container);
  color: var(--text-color); /* Ensure text color adapts */
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

/* Ensure placeholder text is visible in dark mode */
.form-container input::placeholder,
.login-card input::placeholder {
  color: rgba(100, 100, 100, 0.7); /* Default gray for light mode */
}

.dark .form-container input::placeholder,
.dark .login-card input::placeholder {
  color: rgba(200, 200, 200, 0.7); /* Light gray for dark mode */
}

/* Dark mode adjustments */
.dark .form-container input,
.dark .login-card input {
  border: 1px solid rgba(200, 200, 200, 0.2);
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-color); /* Ensures white text in dark mode */
}

/* Subtle outline on focus */
.form-container input:focus,
.login-card input:focus {
  border-color: rgba(130, 130, 255, 0.5);
  box-shadow: 0 0 6px rgba(130, 130, 255, 0.15);
  outline: none;
}

.dark .form-container input:focus,
.dark .login-card input:focus {
  border-color: rgba(180, 180, 255, 0.5);
  box-shadow: 0 0 6px rgba(180, 180, 255, 0.1);
}


/* Login Card Specific */
.login-card input {
  color: #343b34;
}
.login-card input::placeholder {
  color: #272424;
}
.signup-link {
  color: #ff00ff;
  font-family: 'Courier New', Courier, monospace;
  /* Remove bold by default so it only becomes bold on hover */
  font-weight: normal; 
  text-decoration: none; /* optional: remove underline */
}

.signup-link:hover {
  font-weight: bold; /* becomes bold on hover */
}
.login-card p {
  color: #8a8484;
}
.login-container,
otp-container {
  background: var(--bg-container);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.login-card form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-card form input {
  width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.forgot-link-container {
  text-align: right; 
  margin: 0.5rem 0 1rem 0; /* adjust spacing as needed */
}

/* Optional: style the forgot-link differently if you want */
.forgot-link {
  color: #0066cc;
  text-decoration: none;
}

.forgot-link:hover {
  text-decoration: underline;
}

.update-user-container {
  background: var(--bg-container);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgb(0, 0, 0);
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

/* ----------------------
   Dashboard & Bubble Styling
   ---------------------- */
.dashboard {
  padding: 20px;
  background-color: var(--bg-container);
}
.bubble {
  background: var(--bg-container);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

/* Tables, Alerts, Wallet Cards */
.table-dark {
  background-color: var(--bg-container);
  color: var(--text-color);
}
.dark-alert {
  background-color: #2a2a2a;
  border-color: #444;
  color: #fff;
}
.wallet-cards {
  display: flex;
  background-color: transparent;
  flex-direction: column;
  gap: 20px;
}
.mr-2 {
  margin-right: 0.5rem;
}

/* ----------------------
   Additional Overrides (if needed)
   ---------------------- */
.btn-outline-light {
  color: #ffffff;
  border-color: #ffffff;
}

/* Dark Mode Toggle Icon CSS */
.st-sunMoonThemeToggleBtn {
  position: relative;
  cursor: pointer;
}

.st-sunMoonThemeToggleBtn .themeToggleInput {
  opacity: 0;
  width: 100%;
  aspect-ratio: 1;
}

.st-sunMoonThemeToggleBtn svg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease;
  transform: rotate(40deg);
}

.st-sunMoonThemeToggleBtn svg .sunMoon {
  transform-origin: center center;
  transition: inherit;
  transform: scale(1);
}

.st-sunMoonThemeToggleBtn svg .sunRay {
  transform-origin: center center;
  transform: scale(0);
}

.st-sunMoonThemeToggleBtn .themeToggleInput:checked + svg {
  transform: rotate(90deg);
}

.st-sunMoonThemeToggleBtn .themeToggleInput:checked + svg mask > circle {
  transform: translate(16px, -3px);
}

.st-sunMoonThemeToggleBtn .themeToggleInput:checked + svg .sunMoon {
  transform: scale(0.55);
}

.st-sunMoonThemeToggleBtn .themeToggleInput:checked + svg .sunRay {
  animation: showRay1832 0.4s ease 0s 1 forwards;
}

@keyframes showRay1832 {
  0% { transform: scale(0); }
  100% { transform: scale(1); }
}

@keyframes spin-once {
  from {
    transform: rotate(20deg);
  }
  to {
    transform: rotate(90deg);
  }
}

.animate-spin-once {
  animation: spin-once .25s ease-in-out;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px; /* space for spinner */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #ddd;
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff; /* white background */
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  color: #000;
}


.message {
  margin-top: 1rem;
  text-align: center;
}

.message.error {
  color: red;
}

.message.success {
  color: green;
}

.timer {
  font-weight: bold;
  margin-bottom: 1rem;
}
.gradient-bg {
  background-image: var(--bg1-gradient);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.signup-heading {
  color: var(--text-color);
}
