/* Define Light Mode Variables (Default) */
:root {
  /* Overall container */
  --dashboard-bg: #f2f2f2;
  --dashboard-text: #1a1a1a;
  --dashboard-container-bg: rgba(255, 255, 255, 0.0);
  
  /* Alerts */
  --alert-bg: #ffffff;
  --alert-border: #ccc;
  --alert-text: #1a1a1a;
  
  /* Stats cards */
  --card-bg: rgba(255, 255, 255, 0.4);
  --card-shadow: rgba(0, 0, 0, 0.1);
  --card-text: #1a1a1a;
  --card-hover-shadow: rgba(0, 0, 0, 0.15);
  
  /* Subtext */
  --subtext-color: #555;
  
  /* Activity section */
  --activity-bg:rgba(255, 255, 255, 0.4);
  --activity-item-bg: rgba(255, 255, 255, 0.4);
  --activity-hover-bg: #ececec;
  
  /* Activity item borders */
  --activity-received-border: #2ecc70b9;
  --activity-sent-border: #e74c3c;
  --activity-pending-border: #f39c12;
  
  /* Modal */
  --modal-bg: rgba(255, 255, 255, 0.8);
  --modal-text: #1a1a1a;
  --modal-border: rgba(255, 255, 255, 0.0);
  
  /* Buttons */
  --btn-bg: rgba(255, 255, 255, 0.493);                               
  --btn-text: #1a202c;                         
  --btn-border: hsl(261deg 80% 48%);             
  --btn-hover-bg: hsla(261, 80%, 48%, 0.644);           
  --btn-hover-text: #ffffff;                   
  --btn-active-bg: hsl(261deg 80% 48%);          
  --btn-active-text: #ffffff;                   
}


/* Dark Mode Overrides */
.dark:root {
  --dashboard-bg: #0c1929;
  --dashboard-text: #d4d4d4;
  --dashboard-container-bg: rgba(20, 20, 20, 0.0);

  --alert-bg: #2a2a2a;
  --alert-border: #444;
  --alert-text: #fff;
  
  --card-bg:rgba(56, 55, 55, 0.4);
  --card-shadow: rgba(0, 0, 0, 0.3);
  --card-text: #fff;
  --card-hover-shadow: rgba(0, 0, 0, 0.2);
  
  --subtext-color: #8fa3b0;
  
  --activity-bg: rgba(56, 55, 55, 0.4);
  --activity-item-bg: rgba(56, 55, 55, 0.4);
  --activity-hover-bg: #313b46;
  
  --activity-received-border: #2ecc706b;
  --activity-sent-border: #e74c3c;
  --activity-pending-border: #f39c12;
  
  --modal-bg: #1c232ab2;
  --modal-text: #d4d4d4;
  --modal-border: #444444af;
  
  --btn-bg: #1a1a1aa2;        /* e.g., dark background */
  --btn-text: #ffffff;      /* white text */
  --btn-border: #6a11cb;    /* some accent border color in dark mode */
  --btn-hover-bg: #6b11cbb2;  /* or whichever accent you prefer */
  --btn-hover-text: #ffffff;
  --btn-active-bg: #4b078c; /* a slightly darker accent for active state */
  --btn-active-text: #ffffff;
  
}

/* ----------------------
   Updated Styles Using Variables
   ---------------------- */

   .dashboard-container {
    background-color: var(--dashboard-container-bg);
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.0);
    padding: 40px;
    
    /* margin: [top] [right] [bottom] [left] */
    margin: 20px 20px 20px 40px; 
    /* top = 20px, right = auto, bottom = 60px, left = auto (for horizontal centering) */
  
    max-width:  1800px;
  
  }

/* Alert styling for "closed wallet" notifications */
.dark-alert {
  background-color: var(--alert-bg);
  border-color: var(--alert-border);
  color: var(--alert-text);
}

/* Stats row for summary cards */
.stats-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  margin: 0 auto 30px;
  max-width: 1500px;
  justify-items: center;
}

/* Individual stats card */
.stats-card {
  background-color: var(--card-bg);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px var(--card-shadow);
  color: var(--card-text);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.stats-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px var(--card-hover-shadow);
}

.stats-card h4 {
  margin-bottom: 10px;
  color: var(--card-text);
  font-weight: 500;
}

.stats-value {
  font-size: 1.4rem;
  margin-bottom: 5px;
  color: var(--card-text);
}

.stats-subtext {
  font-size: 0.85rem;
  color: var(--subtext-color);
}

/* Activity Section */
.activity-section {
  background-color: var(--activity-bg);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px var(--card-shadow);
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.activity-header h5 {
  margin: 0;
  color: var(--card-text);
}

.activity-header a {
  color: #4186dd;
  text-decoration: none;
  font-size: 0.9rem;
}

.activity-header a:hover {
  text-decoration: underline;
}

/* Recent Activity Items */
.activity-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.activity-item {
  background-color: var(--activity-item-bg);
  border-radius: 6px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: background-color 0.2s;
}

.activity-item:hover {
  background-color: var(--activity-hover-bg);
}

/* Color variants for each activity type */
.activity-item.received {
  border-left: 4px solid var(--activity-received-border);
}
.activity-item.sent {
  border-left: 4px solid var(--activity-sent-border);
}
.activity-item.pending {
  border-left: 4px solid var(--activity-pending-border);
}

.activity-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 60%;
}

.activity-type {
  font-weight: 500;
  color: var(--card-text);
}

.activity-address {
  font-size: 0.9rem;
  color: var(--subtext-color);
}

.activity-meta {
  text-align: right;
}

.activity-amount {
  font-weight: 600;
  color: var(--card-text);
  margin-bottom: 5px;
}

.activity-time {
  font-size: 0.8rem;
  color: var(--subtext-color);
}

/* Modal/Popup overrides */

.custom-modal {
  position: fixed;
  top: 10%;              /* Adjust vertical positioning as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 1200px;      /* Adjust max-width as needed */
  max-height: 80vh;      /* Limit height to 80% of the viewport */
  overflow-y: auto;      /* Add vertical scroll if content overflows */
  padding: 1rem;
  background: var(--bg-container);
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.modal-content {
  background-color: var(--modal-bg);
  border: none;
  border-radius: 8px;
  color: var(--modal-text);
}

.modal-header {
  border-bottom: 1px solid var(--modal-border);
}

.modal-title {
  color: var(--modal-text);
}

.modal-body {
  background-color: var(--modal-bg);
  color: var(--modal-text);
}

.modal-footer {
  border-top: 1px solid var(--modal-border);
}

/* Button Overrides */

button {
  padding: 5px 30px;
  border-radius: 50px;
  cursor: pointer;
  border: 2px solid var(--btn-border);
  background-color: var(--btn-bg);
  color: var(--btn-text);
  font-weight: bold;
  box-shadow: rgba(182, 181, 181, 0.05) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all 0.5s ease;
}

/* Hover */
button:hover {
  letter-spacing: 1.5px;
  background-color: var(--btn-hover-bg);
  color: var(--btn-hover-text);
  box-shadow: 0 2px 10px 0 var(--btn-hover-bg);
}

/* Active (when clicked) */
button:active {
  letter-spacing: 3px;
  background-color: var(--btn-active-bg);
  color: var(--btn-active-text);
  box-shadow: 0 0 0 0 var(--btn-active-bg);
  transform: translateY(10px);
  transition: 100ms;
}




.btn-primary {
  background: var(--btn-bg);
  color: white;
  border: none;
  padding: 12px 16px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
  display: inline-block;
  width: auto;
  max-width: none;
}

.btn-secondary {
  background-color: var(--alert-bg);
  border-color: var(--alert-border);
  color: var(--alert-text);
  padding: 12px 16px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
  display: inline-block;
  width: auto;
  max-width: none;
}

.btn-outline-primary {
  color: #4186dd;
  border-color: #4186dd;
  padding: 12px 16px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease;
  display: inline-block;
  width: auto;
  max-width: none;
}

.btn-outline-light {
  color: #fff;
  border-color: #fff;
}

/* Toggle Button Styling */
.toggle {
  background-color: #fff;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  box-shadow: 0 0 50px 20px rgba(0, 0, 0, 0.1);
  line-height: 1;
  transition: background-color 0.3s;
}

.input {
  display: none;
}

.icon {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  transition: transform 500ms;
}

/* Initially, show the moon icon and hide the sun icon */
.icon--moon {
  transition-delay: 200ms;
  transform: scale(1) rotate(0deg);
}

.icon--sun {
  transform: scale(0) rotate(0deg);
}

/* When the checkbox is checked, hide the moon and show the sun */
#switch:checked + .icon--moon {
  transform: rotate(360deg) scale(0);
}

#switch:checked ~ .icon--sun {
  transition-delay: 200ms;
  transform: rotate(360deg) scale(1);
}

.modal-backdrop {
  z-index: 1040 !important;
}

.modal {
  z-index: 1050 !important;
}